<template>
    <div class="index-ctn">
        <div class="main-content" style="height: fit-content;">
            <a-tabs v-model="activeTabkey" @change="handleTabChange">
                <a-tab-pane key="2" tab="全部">
                    <a-spin v-show="mainContentLoading && postList.length == 0" :spinning="mainContentLoading">
                        <div style="padding: 16px;">
                            <a-skeleton active avatar title :paragraph="{ rows: 4 }" />
                        </div>
                    </a-spin>
                    <a-spin :spinning="mainContentLoading && postList.length !== 0" tip="正在加载中...">
                        <div ref="listRef">
                            <div v-for="(item, index) in postList">
                                <PostItem :index="index" :postData="item"></PostItem>
                            </div>
                           
                        </div>
                        <a-empty v-if="!mainContentLoading && postList.length == 0" style="margin: 20px;"></a-empty>
                    </a-spin>
                    <div  class="pagination-ctn default-pagination">
                        <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total"
                            show-quick-jumper hideOnSinglePage style="margin: 25px;"/>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="1" tab="我的课题组">
                    <a-spin v-show="mainContentLoading && subscriptPostList.length == 0" :spinning="mainContentLoading">
                        <div style="padding: 16px;">
                            <a-skeleton acitive avatar title :paragraph="{ rows: 4 }" />
                        </div>
                    </a-spin>
                    <a-spin :spinning="mainContentLoading && postList.length !== 0" tip="正在加载中...">
                        <div ref="listRef">
                            <div v-for="(item, index) in postList">
                                <PostItem :index="index" :postData="item"></PostItem>
                            </div>
                           
                        </div>
                       <!--  <a-empty v-if="!mainContentLoading && postList.length == 0" style="margin: 20px;"></a-empty> -->
                    </a-spin>
                    <a-empty v-if="!mainContentLoading && postList.length == 0" style="margin: 20px;"></a-empty>
                    <div  class="pagination-ctn default-pagination">
                        <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total"
                            show-quick-jumper hideOnSinglePage style="margin: 25px;"/>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="side-content">
            <div class="recent-hot">
                <SideGapCard title="近期热榜" gapColor="#FF622D">
                    <template>
                        <a-skeleton v-if="isRecentHotLoading" active :title="false"
                            :paragraph="{ rows: 10, width: '100%' }"></a-skeleton>
                        <div v-else>
                            <div v-for="(item, index) in recentHotList" class="hot-item" :title="item.title">
                                <div :class="['hot-item-index', `hot-item-index-${index}`]">
                                    {{ index + 1 }}
                                </div>
                                <div class="hot-item-text">
                                    <a :href="getPostUrl(item)" target="_blank">
                                       {{  item.title  }}
                                    </a>
                                </div>
                            </div>
                            <div v-if="!isRecentHotLoading && recentHotList.length  == 0">
                                <a-empty ></a-empty>
                            </div>
                        </div>
                    </template>
                </SideGapCard>

            </div>
            <div class="recommand-research-team-ctn">
                    <SideGapCard title="推荐话题" gapColor="#2F54EB">
                        <template>
                            <a-skeleton :loading="isRecomandTopicLoading" active :title="false"
                                :paragraph="{ rows: 10, width: '100%' }" :style="{ padding: isRecomandTopicLoading ? '20px' : ''}">
                                <div class="recomand-list">
                                    <div v-for="(item,index) in recommandTopicList" >
                                        <TopicListItem :index="index" :subjectData="item" @onUpdate="newVal => item.isFollow = newVal.isFollow"></TopicListItem>
                                    </div>
                                </div>
                                <a-empty v-if="recommandTopicList.length === 0" style="margin: 20px;"></a-empty>
                            </a-skeleton>
                        </template>
                    </SideGapCard>
                </div>
        </div>
        <a-back-top />
    </div>
</template>

<script >
import SideGapCard from '../components/sideGapCard.vue';
import PostItem from '../components/postItem.vue';
import { onMounted, reactive, ref, watch } from 'vue-demi';
import { useInfiniteScroll, useScroll } from '@vueuse/core';
import TopicListItem from '../user/components/topicListItem.vue';
import { getHotSubject, getRecentHotList, getIndexPaperList } from '@/api/paperManage.js'
//import { getIndexPaperList } from '@/api/researchGroupPaperManage.js';
export default {
    /*  data() {
         return {
             activeTabkey: "all", // 活跃的tab
             mainContentLoading: false,   // 是否正在加载
             postList: [],   // 帖子列表
             subscriptPostList: [],  // 仅我关注的课题组
             isRecentHotLoading: true,   // 近期热榜是否正在加载
             isRecommendLoading: false,   // 推荐是否加载
             mouseInRecentSubscribeItemIndex: -1, // 鼠标进入已关注按钮的 序号
             current: 1, // 当前页
             total: 1000, // 总页数
             pageSize: 20, // 单页数目
         };
     },
     methods: {
         // 切换
         handleTabChange(activeKey) {
             if (activeKey === 'all') {
                 this.postList = [];
                 this.mainContentLoading = true
             }
             else if (activeKey === 'subscribe') {
                 this.subscriptPostList = [];
                 this.mainContentLoading = true;
             }
             this.activeTabkey = activeKey;
         },
         handleRecentSubscribeMouseIn(e, index) {
             this.mouseInRecentSubscribeItemIndex = index;
         },
         handleRecentSubscribeMouseLeave(e, index) {
             this.mouseInRecentSubscribeItemIndex = -1;
         },
         handlePageChange() {
             this.mainContentLoading = true
             window.scrollTo({
                 top: 0,
                 behavior: "smooth"
             });
             setTimeout(() => {
                 this.mainContentLoading = false
             }, 2000)
         }
 
     },
     components: { 
         SideGapCard,
         PostItem
     },
     mounted() {
         setTimeout(() => {
             this.postList.splice(0,0, ...[1,2,3,4,5,6,7,8,9,10])
             this.mainContentLoading = false;
             this.isRecentHotLoading = false;
         }, 2000)
 
     } */
    components: {
        SideGapCard,
        PostItem
    },
    components: {
        SideGapCard,
        PostItem,
        TopicListItem
    },
    setup(props, context) {
        const activeTabkey = ref("2"); // 活跃的tab
        const mainContentLoading = ref(true);   // 是否正在加载
        const postList = ref([]);   // 帖子列表
        const subscriptPostList = ref([]);  // 仅我关注的课题组
        
        const isRecommendLoading = ref(false);   // 推荐是否加载
        const mouseInRecentSubscribeItemIndex = ref(-1); // 鼠标进入已关注按钮的 序号
        const current = ref(1); // 当前页
        const total = ref(0); // 总页数
        const pageSize = ref(10); // 单页数目

        // 切换标签
        const handleTabChange = (activeKey) => {
            if (activeKey === '1') {
                postList.value = [];
                mainContentLoading.value = true
                current.value = 1;
                handlePageChange()
            }
            else if (activeKey === '2') {
                subscriptPostList.value = [];
                mainContentLoading.value = true;
                current.value = 1;
                handlePageChange()
            }
            activeTabkey.value = activeKey;
        }

        // 光标进入已关注按钮
        const handleRecentSubscribeMouseIn = (e, index) => {
            mouseInRecentSubscribeItemIndex.value = index;
        }

        // 光标离开已关注按钮
        const handleRecentSubscribeMouseLeave = (e, index) => {
            mouseInRecentSubscribeItemIndex.value = -1;
        }

        // 页数改变
        const handlePageChange = async () => {
            mainContentLoading.value = true
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
           /*  setTimeout(() => {
                mainContentLoading.value = false
            }, 2000) */
            const res = await getIndexPaperList({
                Type: activeTabkey.value,
                PageSize: pageSize.value,
                PagenNum : current.value
            });
            if(res.success) {
                postList.value = res.data.data.map(post => {
                    return {
                        ...post,
                        //id: post.paperId,
                       // commentCount: post.commitCount,
                       userId: post.createdUserId,
                        avatar: post.userAvatar
                    }
                });
                total.value = res.data.count;
            }
            mainContentLoading.value = false;
        }

        // 无线滚动元素
        const listRef = ref(null);


        onMounted(() => {
           /*  setTimeout(() => {
                postList.value.splice(0, 0, ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
                mainContentLoading.value = false;
                isRecentHotLoading.value = false;
                total.value = 1000;
            }, 2000) */
            handlePageChange();
            initRecommandList();
            getHotList();
            // 保留无限滚动的功能拓展性
            /* useInfiniteScroll(ref(window), () => {
                mainContentLoading.value = true;
                setTimeout(() => {
                    postList.value.splice(postList.value.length - 1, 0, ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
                    mainContentLoading.value = false
                }, 2000)
            }, {
                distance: 200
            }) */
        })

        // 推荐话题是否在加载
        const isRecomandTopicLoading = ref(true);

        // 推荐话题列表
        const recommandTopicList = ref([]);

        // 初始化推荐列表
        const initRecommandList = async () => {
            isRecomandTopicLoading.value = true;
            const res = await getHotSubject();
            if(res.success) {
                recommandTopicList.value = res.data;
            }
            isRecomandTopicLoading.value = false;
        }

        const isRecentHotLoading = ref(true);   // 近期热榜是否正在加载

        const recentHotList = ref([]);  // 近期热榜列表

        const getHotList = async () => {
            isRecentHotLoading.value = true;
            const res = await getRecentHotList();
            if(res.success) {
                recentHotList.value = res.data;
            }
            isRecentHotLoading.value = false;
        }

        const { $router } = context.root;

        const getPostUrl = (item) => {
            const href = $router.resolve({
                name: 'bbsPost',
                params: {
                    id: item.id
                }
            })
            return href.href
        }



        return {
            activeTabkey,
            mainContentLoading,
            postList,
            subscriptPostList,
            isRecentHotLoading,
            isRecommendLoading,
            mouseInRecentSubscribeItemIndex,
            current,
            total,
            pageSize,
            handleTabChange,
            handleRecentSubscribeMouseIn,
            handleRecentSubscribeMouseLeave,
            handlePageChange,
            listRef,
            isRecomandTopicLoading,
            recommandTopicList,
            recentHotList,
            getPostUrl
        }
    }
}
</script>

<style lang="less" scoped>
.index-ctn {
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;

    .main-content {
        width: 0px;
        flex: 1;
        background: #fff;
        border: 0.5px solid #ebebeb;

        /deep/ .ant-tabs-nav-scroll {
            margin-left: 20px;
        }

        /deep/ .ant-tabs-nav .ant-tabs-tab {
            padding: 14px 14px;

            &:hover {
                color: @srims-primary-color;
            }
        }

        /deep/ .ant-tabs-ink-bar {
            background-color: @srims-primary-color;
            // transform: scaleX(0.5) !important;
        }

        /deep/ .ant-tabs-nav .ant-tabs-tab-active {
            color: @srims-primary-color;
        }

        .pagination-ctn {
            //padding: 25px;
            text-align: center;
            //border-left: .5px solid #ebebeb;
            // border-right: .5px solid #ebebeb;
            //border-bottom: .5px solid #ebebeb;
        }
    }

    .side-content {
        width: 330px;
        margin-left: 20px;

        .recent-hot {

            .hot-item {
                display: flex;
                align-items: center;

                .hot-item-index {
                    font-size: 18px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.45);
                    width: 24px;
                    text-align: center;
                }

                .hot-item-index-0 {
                    color: #FF4D4F;
                }

                .hot-item-index-1 {
                    color: #FF8615;
                }

                .hot-item-index-2 {
                    color: #FFBF41;
                }

                .hot-item-text {
                    margin-left: 7px;
                    cursor: pointer;


                    font-size: 14px;
                    width: 0;
                    flex: 1;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    a {
                        text-decoration: none;
                        color: rgba(0, 0, 0, 0.65);
                        transition: .3s color ease-in-out;
                    }

                    &:hover {
                        a {
                            color: #1890ff;
                            transition: .3s color ease-in-out;
                        }

                    }
                }
            }

            .hot-item+.hot-item {
                margin-top: 18px;
            }
        }

        .recommand-research-team-ctn {
            margin-top: 20px;

            /deep/ .gap-card-content {
                padding: 0;
            }
        }
    }

}
</style>